import React from 'react'
import '../../index.css'
import Button from '@material-ui/core/Button'

function LoginLink() {
    return (
        // <div className="loginLink">Login</div>
        <Button style={{backgroundColor: "#F4881C", color: "#FAFAFA"}} variant="contained" disableElevation
            onClick={() => window.open("https://alpha.epiphani.ai", "_blank")}>
            Login
        </Button>
    )
}

export default LoginLink;