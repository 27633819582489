import React, { Component } from 'react';
import './App.css';
import Header from './components/header/Header';
import Footer from './components/footer/Footer'

class Error extends Component {
    render() { return (
      <>
      <div className="homePageBg"></div>
      <div className="App flexContainer flexCol">
        <Header />
        Oops.... maybe we can guide you a bit better....
        <Footer />
      </div>
      </>
    );
    }
}

export default Error;