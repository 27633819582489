import React from 'react'
import EpiLogo from '../epiLogo/EpiLogo'
import ContactLink from '../contactLink/ContactLink'
import LoginLink from '../loginLink/LoginLink'
import TryIt from '../tryIt/TryIt'
import '../../styles/Header'
import { Grid } from '@material-ui/core'

function Header(props) {
    return (
        <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>
        <div className="header">
      
            <EpiLogo part="logoOnly"></EpiLogo>
            <EpiLogo part="logoText"></EpiLogo>
            
            <div className="flexGrow">
                <div className="flexContainer flexEnd flexBaseline" style={{marginRight: "2rem"}}>
                    
                <div style={{marginRight:"2rem"}}><ContactLink /></div>
                {/* <LoginLink /> */}
                <TryIt textLink={true}/>
                </div>
            </div>
        </div>
        </Grid>
        </Grid>
    )
}

export default Header;