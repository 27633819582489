import React, {useEffect} from 'react'
import { Grid } from '@material-ui/core';
/*import ReactGA from 'react-ga';

const ALPHA_HOST = 'epiphani.ai'
const MASTER_HOST = 'staging.epiphani.ai.s3-website-us-west-2.amazonaws.com';
const LOCAL_HOST = 'localhost:1234' */

function ContactLink() {
/*
    //Google Analytics start
  var trackingID = ""
  if (window.location.host.toLowerCase() === ALPHA_HOST) {
   // console.log("Alpha GoogleAnalytics Tracking")
    
      //console.log("data ",data)
      //console.log(data.default.GOOGLE_ANALYTICS_TRACKING_ID.TRACKING_ID)
      trackingID = "UA-175911025-1"
      //console.log("trackingID ",trackingID)
    
  } else if ((window.location.host.toLowerCase() === MASTER_HOST) ||
    (window.location.host.toLowerCase() === LOCAL_HOST)) {
   // console.log("Master/Local GoogleAnalytics Tracking")
    trackingID = "UA-175911025-1"
  }

  ReactGA.initialize(trackingID); 
  
  //Event - Add custom tracking event.
  const Event1 = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  };

  //Google Analytics end
  */

  function Mailto({ email, subject, body, ...props }) {
     // console.log("mailTo")
    //Event1("epiphani.ai", "contactUsAction", "epiphani.ai")
    return (
      <a className="linkText" target="_blank" href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>{props.children}</a>
    );
}

    return (
        <Grid container>
            
        <Mailto email="feedback@epiphani.ai" subject="Regarding epiphani playbooks service" body="Hello epiphani team, ">
        <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>
                <div className="contactLink">Contact Us</div>
                </Grid>
        </Mailto>
        </Grid>
        
    )
}

export default ContactLink;