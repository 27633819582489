import React, { useState, useEffect } from 'react';
import '../../App.css';
import '../../styles/MainPage4.css';
import '../../styles/Epiplaybooks.css'
import '../../styles/VideoLink.css'
import VideoLink from '../../components/videolink/VideoLink'
import HelpLink from '../../components/helplink/HelpLink'
import Header from '../header/Header';
import Footer from '../footer/Footer'
import { Popover } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ReactPlayer from 'react-player';
import ReactGA from 'react-ga';

const ALPHA_HOST = 'epiphani.ai'
const MASTER_HOST = 'staging.epiphani.ai.s3-website-us-west-2.amazonaws.com';
const LOCAL_HOST = 'localhost:1234'

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  return (
    <div className={showHideClassName}>
        {children}
        <HighlightOffIcon className="vidCloseButton" onClick={handleClose}></HighlightOffIcon>
        {/* <button onClick={handleClose}>close</button> */}
    </div>
  );
};

function EpiPlaybooks(props) {

  //Google Analytics start
  var trackingID = ""
  if (window.location.host.toLowerCase() === ALPHA_HOST) {
  //  console.log("Alpha GoogleAnalytics Tracking")
    
      //console.log("data ",data)
      //console.log(data.default.GOOGLE_ANALYTICS_TRACKING_ID.TRACKING_ID)
      trackingID = "UA-175911025-1"
      //console.log("trackingID ",trackingID)
    
  } else if ((window.location.host.toLowerCase() === MASTER_HOST) ||
    (window.location.host.toLowerCase() === LOCAL_HOST)) {
  //  console.log("Master/Local GoogleAnalytics Tracking")
    trackingID = "UA-175911025-1"
  }

  ReactGA.initialize(trackingID); 
  
  //Event - Add custom tracking event.
  const Event1 = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  };
  //end of GoogleAnalytics

  const [modalState, setModalState] = useState({ show: false });
  const [vidPlaying, setVidPlaying] = useState(true);
  const [vidPlayingURL, setVidPlayingURL] = useState(null);

  function showModal(vidURL) {
      setVidPlayingURL(vidURL);
      setModalState({ show: true });
      setVidPlaying(true);
  };
  
  function hideModal() {
      setModalState({ show: false });
      setVidPlaying(false);
      setVidPlayingURL(null);
  };

  useEffect(() => {
    document.addEventListener('keyup', (e) => {
        if (e.keyCode === 27) props.hideModal();
    })
  }, []);

  const linkType = {
    VIDEO: "video",
    HELPTEXT: "helpText"
  }

  const DevVideos = [
      { url: "https://s3-us-west-2.amazonaws.com/epiphani.ai/Videos/Video-Automate+developer+tasks.mp4",
        type: linkType.VIDEO, caption: "Launch and Terminate Dev Resources" },
      { url: "https://epiphanihelp.freshdesk.com/support/solutions/articles/63000206739-ready-to-use-sample-playbooks#Sample-Playbook-%3A%3A-Stop-Kubernetes-Pods",
        type: linkType.HELPTEXT, caption: "Manage Kubernetes Clusters" },
      { url: "https://s3-us-west-2.amazonaws.com/epiphani.ai/Videos/Video+8+-+Clone+%26+Customize+Playbook.mp4",
        type: linkType.VIDEO, caption: "Customize Sample Playbooks" },
    ]

    const DevOpsVideos = [
      { url: "https://s3-us-west-2.amazonaws.com/epiphani.ai/Videos/war+room+-+v2.mp4",
        type: linkType.VIDEO, caption: "Launch a War Room for incidents" },
      // { url: "https://epiphanihelp.freshdesk.com/support/solutions/articles/63000206739-ready-to-use-sample-playbooks#Sample%C2%A0Playbook%3A%3A-Sample-HealthCheck",
      //   type: linkType.HELPTEXT, caption: "Run periodic health checks" },
      { url: "https://s3-us-west-2.amazonaws.com/epiphani.ai/Videos/healthcheck+video.mp4",
        type: linkType.VIDEO, caption: "Run periodic health checks" },
      // { url: "https://epiphanihelp.freshdesk.com/support/solutions/articles/63000206739-ready-to-use-sample-playbooks#Sample-Playbook-%3A%3A-On-call-Schedules",
      //   type: linkType.HELPTEXT, caption: "Get schedule and on-call information" },
        { url: "https://s3-us-west-2.amazonaws.com/epiphani.ai/Videos/on-call+schedules.mp4",
        type: linkType.VIDEO, caption: "Get schedule and on-call information" },
    ]

    const InfraVideos = [
      { url: "https://s3-us-west-2.amazonaws.com/epiphani.ai/Videos/multi-cloud+final.mp4",
        type: linkType.VIDEO, caption: "Configure a Multi-cloud VPN" },
      { url: "https://epiphanihelp.freshdesk.com/support/solutions/articles/63000206739-ready-to-use-sample-playbooks#Sample%C2%A0Playbook-%3A%3A%C2%A0Start-Dev-Resources",
        type: linkType.HELPTEXT, caption: "Orchestrate Virtual Machines" },
      { url: "https://epiphanihelp.freshdesk.com/support/solutions/articles/63000206739-ready-to-use-sample-playbooks#Sample-Playbook-%3A%3A-Remediate-OVS-Firewall",
        type: linkType.HELPTEXT, caption: "Manage OVS Firewalls" },
    ]

    function handleLoginAnonymousTryYourself() {
      Event1("epiphani.ai", "LoginAnonymousTryYourselfClcik", "epiphani.ai")
      window.open("https://alpha.epiphani.ai", "_blank")
    }

    return (
      <>     
      <div className="flexContainer flexCol epiplaybooks">
        <div className="sectionTitle">
          <span>What can epiphani playbooks do for me ?</span>
        </div>
        <div style={{height: "2vh", maxHeight: "100px"}}></div>
        <div className="subSectionTitle">
          <span>Developing software</span>
        </div>
        <div className="flexContainer flexRow flexSpaceEvenly">
          {
            DevVideos.map((v, n) => {
              return v.type === linkType.VIDEO ? 
              <VideoLink key={n} url={v.url} caption={v.caption} playFn={props.showModal}/> :
              <HelpLink key={n} url={v.url} caption={v.caption} playFn={props.showModal}/>
            })
          }
        </div>
        <div style={{height: "2vh", maxHeight: "100px"}}></div>
        <div className="subSectionTitle">
          <span>Running DevOps</span>
        </div>
        <div className="flexContainer flexRow flexSpaceEvenly">
          {
            DevOpsVideos.map((v, n) => {
              return v.type === linkType.VIDEO ? 
              <VideoLink key={n} url={v.url} caption={v.caption} playFn={props.showModal}/> :
              <HelpLink key={n} url={v.url} caption={v.caption} playFn={props.showModal}/>
            })
          }
        </div>
        <div style={{height: "2vh", maxHeight: "100px"}}></div>
        <div className="subSectionTitle">
          <span>Managing Infrastructure</span>
        </div>
        <div className="flexContainer flexRow flexSpaceEvenly">
          {
            InfraVideos.map((v, n) => {
              return v.type === linkType.VIDEO ? 
              <VideoLink key={n} url={v.url} caption={v.caption} playFn={props.showModal}/> :
              <HelpLink key={n} url={v.url} caption={v.caption} playFn={props.showModal}/>
            })
          }
        </div>
        {/* <div style={{height: "25vh", maxHeight: "100px"}}></div> */}
        {/* <div style={{height: "2vh", maxHeight: "100px"}}></div> */}
        <div className="centerize">
            <div className="linkText trySampleButton"
                        onClick={handleLoginAnonymousTryYourself}>
                        Login anonymously and try it yourself!</div>
            <div className="smallPrint" style={{marginTop:"1rem"}}>No registration required</div>
        </div>
      <Modal show={modalState.show} handleClose={hideModal}>
          {/* <ReactPlayer controls playing={vidPlaying} config={{ youtube: { autoplay: false } }} url={vidPlayingURL} /> */}
          <div className="videoContainer">
              <ReactPlayer
                  controls width="100%" height="100%" playing={vidPlaying}
                  config={
                      { youtube: { autoplay: false } },
                      { file: { 
                          attributes: {
                            onContextMenu: e => e.preventDefault()
                          } 
                        }
                      } 
                  } url={vidPlayingURL} />
          </div>
      </Modal>
      </div>
      </>
    );
}

export default EpiPlaybooks;