import React from 'react';
import logo from '../../../public/assets/epiphani logo-v2.svg';
import logoText from '../../../public/assets/epiphani text logo-v2.svg';
import '../../../src/styles/EpiLogo';

function EpiLogo(props) {
    return (
        <div>
            { props.part === "logoOnly" ? 
                <img src={logo} className="App-logo" alt="logoonly" /> : null }
            { props.part === "logoText" ?
                <img src={logoText} className="App-logo-text" alt="logotext" /> : null }
            { props.part === "logoTextGrayscale" ?
                <img src={logoText} className="App-logo-text grayscale" alt="logotextgreyscale" /> : null }    
        </div>
    )
}

export default EpiLogo;