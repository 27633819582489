import React, { useEffect, useState, useRef } from 'react'
import ReactFullpage from '@fullpage/react-fullpage';
import '../../styles/MainPage4'
import Carousel, { consts } from 'react-elastic-carousel';
import MainCarouselItem1 from '../../components/mainPage/MainCarouselItem1';
import MainCarouselItem2 from '../../components/mainPage/MainCarouselItem2';
import MainCarouselItem3 from '../../components/mainPage/MainCarouselItem3';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Circle from '../mainPage/Circle';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ReactGA from 'react-ga';
const ALPHA_HOST = 'epiphani.ai'
const MASTER_HOST = 'staging.epiphani.ai.s3-website-us-west-2.amazonaws.com';
const LOCAL_HOST = 'localhost:1234'
function MainSplash(props) {
    //Google Analytics start
  var trackingID = ""
  if (window.location.host.toLowerCase() === ALPHA_HOST) {
    //console.log("Alpha GoogleAnalytics Tracking")
      //console.log("data ",data)
      //console.log(data.default.GOOGLE_ANALYTICS_TRACKING_ID.TRACKING_ID)
      trackingID = "UA-175911025-1"
      //console.log("trackingID ",trackingID)
  } else if ((window.location.host.toLowerCase() === MASTER_HOST) ||
    (window.location.host.toLowerCase() === LOCAL_HOST)) {
  //  console.log("Master/Local GoogleAnalytics Tracking")
    trackingID = "UA-175911025-1"
  }
  ReactGA.initialize(trackingID); 
  //Event - Add custom tracking event.
  const Event1 = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  };
  //end of GoogleAnalytics
    const carousel = useRef();
    // const carouselTimings = [ 13.75, 8.5, 13.75 ];
    const carouselTimings = [ 6, 6, 6 ];
    const [nowShowingIndex, setNowShowingIndex] = useState(0);
    const [modalState, setModalState] = useState({ show: false });
    const [vidPlaying, setVidPlaying] = useState(true);
    const [vidPlayingURL, setVidPlayingURL] = useState(null);
    const [animatePage, setAnimatePage] = useState(0);
    const [scrollOffset, setScrollOffset] = useState(0);
    useEffect(() => {
        window.onscroll = () => {
          setScrollOffset(window.pageYOffset)
        }
    }, []);
    function customPagination(pages, activePage, onClick) {
        // setAnimatePage(activePage)
        return (
        <div className="flexContainer flexRow" style={{marginTop: "10px"}}>
            {pages.map(page => {
            const isActivePage = activePage === page
            return (
                <Circle
                key={page}
                onClick={() => onClick(page)}
                active={isActivePage}
                />
            )
            })}
        </div>
        );
    }
    function showModal(vidURL) {
        setVidPlayingURL(vidURL);
        setModalState({ show: true });
        setVidPlaying(true);
    };
    function hideModal() {
        setModalState({ show: false });
        setVidPlaying(false);
        setVidPlayingURL(null);
    };
    useEffect(() => {
        document.addEventListener('keyup', (e) => {
            if (e.keyCode === 27) hideModal();
        })
        AOS.init({duration: 1200});
    }, []);
    function rotateCarousel() {
        carousel.current.goTo(nowShowingIndex);
        setTimeout(() => { 
            setNowShowingIndex((p) => { return (p+1) % carouselTimings.length});
            }, carouselTimings[nowShowingIndex] * 1000
        );
    }
    // useEffect(() => {
    //     rotateCarousel();
    // }, [nowShowingIndex]);
    function customArrows({ type, onClick, isEdge }) {
        const pointer = type === consts.PREV ? <NavigateBeforeIcon /> : <NavigateNextIcon />
        return(
            <Button onClick={onClick} disabled={isEdge} className="carouselButton">
                {pointer}
            </Button>
        )
    }
    function handleTryeSamplePlaybookClick() {
        Event1("epiphani.ai", "tryeSamplePlaybookClick", "epiphani.ai")
        window.open("https://alpha.epiphani.ai", "_blank")
    }
    return (
        <>
        <div className="mainsplash">
        <div className="flexContainer flexRow flexCenter">
            <Carousel
                    ref={carousel}
                    itemsToShow={1} showArrows={true}
                    renderArrow={customArrows}
                    enableAutoPlay={false}
                    autoPlaySpeed={6000}
                    renderPagination={({ pages, activePage, onClick }) => 
                        customPagination(pages, activePage, onClick)}>
                <MainCarouselItem1 pageNo={0} animatePage={animatePage}/>
                <MainCarouselItem2 pageNo={1} animatePage={animatePage}/>
                <MainCarouselItem3 pageNo={2} animatePage={animatePage}/>
            </Carousel>
        </div>
        <div style={{height: "7vh", maxHeight: "100px"}}></div>
        <div className="centerize giveItSpin">
            <div className="linkText trySampleButton"
                        onClick={handleTryeSamplePlaybookClick}>
                        Try Sample Playbooks in our sandbox</div>
            <div className="smallPrint" style={{marginTop:"1rem"}}>No credit card required</div>
        </div>
        
        <div className="moreContentArrow centerize"
                style={scrollOffset > 100 ? {display: "none"} : {display: "block"}}>
                <ExpandMoreIcon fontSize='large'/>
        </div>
        </div>
        </>
    )
}
export default MainSplash;