  
import styled from "styled-components";

export default styled.div`
  cursor: pointer;
  transition: all 250ms ease-in;
  background-color: ${({ active }) => (active ? "var(--highlight-text-color)": "transparent")};
  transform: scale(${({ active }) => (!active ? 1.1 : 1)});
  border-style: solid;
  border-width: 1px;
  border-color: var(--highlight-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 10px;
  margin: 10px 10px;
  border-radius: 50%;
`;