import React, {useEffect} from 'react'
import '../../index.css'
import Button from '@material-ui/core/Button'
import ReactGA from 'react-ga';

const ALPHA_HOST = 'epiphani.ai'
const MASTER_HOST = 'staging.epiphani.ai.s3-website-us-west-2.amazonaws.com';
const LOCAL_HOST = 'localhost:1234'

function TryIt(props) {
     //Google Analytics start
  var trackingID = ""
  if (window.location.host.toLowerCase() === ALPHA_HOST) {
  //  console.log("Alpha GoogleAnalytics Tracking")
    
      //console.log("data ",data)
      //console.log(data.default.GOOGLE_ANALYTICS_TRACKING_ID.TRACKING_ID)
      trackingID = "UA-175911025-1"
      //console.log("trackingID ",trackingID)
    
  } else if ((window.location.host.toLowerCase() === MASTER_HOST) ||
    (window.location.host.toLowerCase() === LOCAL_HOST)) {
   // console.log("Master/Local GoogleAnalytics Tracking")
    trackingID = "UA-175911025-1"
  }

  ReactGA.initialize(trackingID); 
  
  //Event - Add custom tracking event.
  const Event1 = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  };
  //end of GoogleAnalytics

  function handleOnClick(item) {
    //console.log("TryIt")
    Event1("epiphani.ai", "tryItAction", "epiphani.ai")
    window.open("https://alpha.epiphani.ai", "_blank")
  }
  

    return (
        // <div className="loginLink">Login</div>
        <>
        { props.textLink ?
            <div className="tryItTextLink"
            onClick={handleOnClick}
            >
                Try It</div>
            :
            // <Button style={{backgroundColor: "#F4881C", color: "#FAFAFA"}} variant="contained" disableElevation
            <Button style={{color: "#F4881C", backgroundColor: "var(--main-bg-color)"}} variant="contained" disableElevation
                onClick={handleOnClick}
            >
                Try It!
            </Button>
        }
        </>
    )
}

export default TryIt;