import React, { useState, useEffect } from 'react';
import '../../App.css';
import '../../styles/VideoLink.css'
import '../../styles/Docs.css'
import VideoLink from '../../components/videolink/VideoLink'
import Header from '../header/Header';
import Footer from '../footer/Footer'
import { Popover, Grid } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import warRoomPB from '../../../public/assets/war_room_screenshot.png'
import { scaleService } from 'chart.js';
import { Hidden } from '@material-ui/core';
import ReactGA from 'react-ga';
import KeepMePosted from '../../components/keepmeposted/KeepMePosted'
const ALPHA_HOST = 'epiphani.ai'
const MASTER_HOST = 'staging.epiphani.ai.s3-website-us-west-2.amazonaws.com';
const LOCAL_HOST = 'localhost:1234'
function Docs(props) {
  //Google Analytics start
  var trackingID = ""
  if (window.location.host.toLowerCase() === ALPHA_HOST) {
  //  console.log("Alpha GoogleAnalytics Tracking")
      //console.log("data ",data)
      //console.log(data.default.GOOGLE_ANALYTICS_TRACKING_ID.TRACKING_ID)
      trackingID = "UA-175911025-1"
      //console.log("trackingID ",trackingID)
  } else if ((window.location.host.toLowerCase() === MASTER_HOST) ||
    (window.location.host.toLowerCase() === LOCAL_HOST)) {
  //  console.log("Master/Local GoogleAnalytics Tracking")
    trackingID = "UA-175911025-1"
  }
  ReactGA.initialize(trackingID); 
  //Event - Add custom tracking event.
  const Event1 = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  };
  //end of GoogleAnalytics
    const DocLinks = [
        { title: "Introduction", 
          url: "https://epiphanihelp.freshdesk.com/support/solutions/articles/63000237374-introduction-to-epiphani-playbooks" },
        { title: "Getting started",
          url: "https://epiphanihelp.freshdesk.com/en/support/solutions/articles/63000201318-tutorial-getting-started-with-epiphani-playbooks" },
        { title: "Supported Connectors",
          url: "https://epiphanihelp.freshdesk.com/en/support/solutions/articles/63000244671-supported-connectors" },
        { title: "Sample Playbooks",
          url: "https://epiphanihelp.freshdesk.com/en/support/solutions/articles/63000206739-ready-to-use-sample-playbooks" },
        { title: "Full Video Catalog",
          url: "https://epiphanihelp.freshdesk.com/support/solutions/articles/63000253435-tutorial-catalogue" }
    ]
  /*  function handleNeedInfoMenuClick(url) {
    //  console.log("need info url")
      //console.log(url)
      Event1("epiphani.ai", "needInfoMenuClick", "epiphani.ai")
      window.open(url, "_blank")
    } */
  function handleReadyToGiveASpin() {
    Event1("epiphani.ai", "readyToGiveASpingClick", "epiphani.ai")
    window.open("https://alpha.epiphani.ai", "_blank")
  }
    return (
      <>
      <div className="flexContainer flexCol flexStart docs">
        <div className="sectionTitle">
          <span>Want to learn more?</span>
        </div>
        <img className="screenShotDocs" src={warRoomPB} alt="warRoomPB" />
        {/* <div style={{height: "1vh", maxHeight: "100px"}}></div> */}
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div className="moreInfoLinksBox">
                      {DocLinks.map((l, i) => {
                        return (
                          <div key={i}>
                            {/* <div style={{ height: "1vh", maxHeight: "100px" }}></div> */}
                            <div className="subSectionTitleDocs clickable"
                              onClick={
                                () => {
                                  Event1("epiphani.ai", "needInfoMenuClick: " + l.title, "epiphani.ai")
                                  window.open(l.url, "_blank")
                                }}>
                              <div className="flexContainer flexRow flexSpaceBetween">
                                <div>{l.title}</div>
                                <div>+</div>
                              </div>
                            </div>
                            <div style={{ height: "1vh", maxHeight: "100px" }}></div>
                          </div>
                        )
                      })}
                    </div>
                    <div className="giveItATry centerize" style={{ marginTop: "5vh" }}>
                      <div className="linkText trySampleButton"
                        onClick={handleReadyToGiveASpin}
                      >
                        I'm ready to give this a spin!</div>
                      {/* <div className="smallPrint" style={{ marginTop: "1rem" }}>No registration required</div> */}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} style={{zIndex: 100}}>
                    {/* <div style={{ overflow: "hidden", opacity: "100%" }}  > */}
                      {/* <img className="screenShotDocs" src={warRoomPB} alt="warRoomPB" /> */}
                      {/* <div className="captionText centerize">Manual work is wasteful and error prone</div> */}
                    {/* </div> */}
                    <KeepMePosted Event1={Event1} />
                  </Grid>
                </Grid>
                {/* <img className="screenShotDocs" src={warRoomPB} alt="warRoomPB" /> */}
                <Footer />
              </Grid>
            </Grid>

      </div>
      </>
    );
}
export default Docs;