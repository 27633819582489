import React, { Component } from 'react';
import '../../App.css';
import '../../styles/VideoLink.css'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import ReactGA from 'react-ga';

const ALPHA_HOST = 'epiphani.ai'
const MASTER_HOST = 'staging.epiphani.ai.s3-website-us-west-2.amazonaws.com';
const LOCAL_HOST = 'localhost:1234'

function VideoLink(props) {
    
    //Google Analytics start
  var trackingID = ""
  if (window.location.host.toLowerCase() === ALPHA_HOST) {
   // console.log("Alpha GoogleAnalytics Tracking")
    
      //console.log("data ",data)
      //console.log(data.default.GOOGLE_ANALYTICS_TRACKING_ID.TRACKING_ID)
      trackingID = "UA-175911025-1"
      //console.log("trackingID ",trackingID)
    
  } else if ((window.location.host.toLowerCase() === MASTER_HOST) ||
    (window.location.host.toLowerCase() === LOCAL_HOST)) {
  //  console.log("Master/Local GoogleAnalytics Tracking")
    trackingID = "UA-175911025-1"
  }

  ReactGA.initialize(trackingID); 
  
  //Event - Add custom tracking event.
  const Event1 = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  };
  //end of GoogleAnalytics

    return (
      <>
      <div className="flexContainer flexColumn" style={{margin: "10px"}}>
        <div className="flexContainer flexCol centerize" style={{width: "25vw"}}>
            <div className="screenIcon centerize">
                <PlayCircleFilledIcon 
                    fontSize='large'
                    style={props.url !== "" ? {color: "var(--video-icon-playbutton)"} :
                        {color: "lightgrey"}}
                    onClick={() => { 
                        Event1("epiphani.ai", "VideoLinkClcik: " + props.caption, "epiphani.ai")
                        props.playFn(props.url) 
                        
                        }}/>
            </div>
            <div style={{marginTop:"5px"}}></div>
            <div className="videoCaption">
                {props.caption}
            </div>
        </div>
      </div>
      </>
    );
}

export default VideoLink;