import React from 'react'
import EpiLogo from '../epiLogo/EpiLogo'
import ContactLink from '../contactLink/ContactLink'
import LoginLink from '../loginLink/LoginLink'
import '../../styles/Footer.css'
import ContactMe from '../contactme/ContactMe'
function Mailto({ email, subject, body, ...props }) {
    return (
      <a className="feedbackLinkText" target="_blank" href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>{props.children}</a>
    );
}
function Footer(props) {
    return (
        <div className="footer">
            {/* <hr /> */}
                <div className="flexContainer " style={{marginLeft: "1rem", marginRight: "1rem", alignItems: "center"}}>
                <div style={{marginLeft: "1em"}}>
                    {/* <EpiLogo part="logoTextGrayscale" /> */}
                </div>
                {/* <div className="linkText" style={{marginLeft: "2rem"}}>Privacy</div>
                <div className="linkText" style={{marginLeft: "2rem"}}>Terms</div> */}
                <Mailto email="feedback@epiphani.ai" subject="Feedback regarding your service" body="Hello epiphani team, ">
                    feedback@epiphani.ai
                </Mailto>
                {/* <div className="linkText" style={{marginLeft: "2rem"}}>feedback@epiphani.ai</div> */}
                {/* <div className="linkText" style={{marginLeft: "2rem"}}>About</div> */}
            </div>
            <div className="epiCopyRight" style={{textAlign: "center"}}>
                &copy;2020 epiphani Inc. All rights reserved.
            </div>
        </div>
    )
}
export default Footer;