import React from 'react';
import '../../App.css';
import '../../styles/EpiPlaybooks.css';
import '../../styles/MainPage4.css';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ReactPlayer from 'react-player';

function VideoModal({ handleClose, show, children, vidPlaying, vidPlayingURL}) {
    const showHideClassName = show ? "modal display-block" : "modal display-none";
  
    return (
      <div className={showHideClassName}>
        <section className="modal-main">
          <div className="videoContainer">
              <ReactPlayer
                  controls width="100%" height="100%" playing={vidPlaying}
                  config={
                      { youtube: { autoplay: false } },
                      { file: { 
                          attributes: {
                            onContextMenu: e => e.preventDefault()
                          } 
                        }
                      } 
                  } url={vidPlayingURL} />
          </div>
          <HighlightOffIcon className="vidCloseButton" onClick={handleClose}></HighlightOffIcon>
        </section>
      </div>
    );
  };

  export default VideoModal;