import React from 'react';
import { withFormsy } from 'formsy-react';

function InputField(props) {

  function changeValue(event) {
    // setValue() will set the value of the component, which in
    // turn will validate it and the rest of the form
    // Important: Don't skip this step. This pattern is required
    // for Formsy to work.
    props.setValue(event.currentTarget.value);
  }

  const className = 'form-group' + (props.className || ' ') + (props.required ? 'required' : ' ') + (props.showError ? 'error' : ' ');
  const errorMessage = props.showError ? props.validationError : null;
  return (
    <div className={className}>
      <label htmlFor={props.name}>{props.title}</label>
      <input
        type={props.type || 'text'}
        name={props.name}
        id={props.name}
        onChange={changeValue}
        value={props.value}
        checked={props.type === 'checkbox' && props.value ? 'checked' : null}
      />
      <span className='validation-error'>{errorMessage}</span>
    </div>
  );

}

export default withFormsy(InputField);