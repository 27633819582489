import React, { useEffect, useState, useRef } from 'react'
import ReactFullpage from '@fullpage/react-fullpage';
import '../../app.css'
import '../../styles/MainPage4'
import vmCreateGIF from '../../../public/assets/create\ vm.gif';
import warRoomCreationGIF from '../../../public/assets/war\ room.gif';
import multiCloudGIF from '../../../public/assets/multi-cloud\ loop.gif';
import pbEditor from '../../../public/assets/playbook_screenshot.png';
import chatInterface from '../../../public/assets/chatinterface.png';
import pbNodeTriangle from '../../../public/assets/playbookNodeTriangle.png'
import Button from '@material-ui/core/Button';
import Circle from './Circle';
import ReactPlayer from 'react-player';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AOS from 'aos';
import 'aos/dist/aos.css';
import EpiPlaybooks from '../epiplaybooks/EpiPlaybooks';
import MainSplash from '../mainsplash/MainSplash';
import Docs from '../docs/Docs';
import { Grid } from '@material-ui/core';
import ContactLink from '../contactLink/ContactLink';
import ContactMe from '../contactme/ContactMe';
const LEARNMOREURL = "https://epiphanihelp.freshdesk.com/a/solutions/articles/63000237374"
const EXPLORESAMPLEPLAYBOOKSURL = "https://epiphanihelp.freshdesk.com/support/solutions/articles/63000206739-ready-to-use-sample-playbooks"
// const CREATEPLAYBOOKVID = "https://youtu.be/DAOqdLNXDIs"
const CREATEPLAYBOOKVID = "https://s3-us-west-2.amazonaws.com/epiphani.ai/Videos/SingleNodePlaybook.mp4"
function MainPage(props) {
    const [vidPlayingURL, setVidPlayingURL] = useState(null);
    const [animatePage, setAnimatePage] = useState(-1);
    const [scrollOffset, setScrollOffset] = useState(0);
    useEffect(() => {
        window.onscroll = () => {
          setScrollOffset(window.pageYOffset)
        }
        AOS.init({duration: 1200});
    }, []);
    return (
      <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>
        <ReactFullpage
          //fullpage options
          licenseKey = {'0C70B647-D5E14725-9EE20AFB-8932585A'}
          scrollingSpeed = {800} /* Options here */
          sectionsColor={['white', 'white', 'white']}
          // navigation
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
              <div className="section">
                <MainSplash />
              </div>
              <div className="section">
                <EpiPlaybooks showModal={props.showModal} hideModal={props.hideModal} />
              </div>
              <div className="section">
                <Docs />
              </div>
              </ReactFullpage.Wrapper>
          );
        }}
        />
        </Grid>
        </Grid>
    )
}
export default MainPage;