import React, { Component, useState } from 'react';
import './app.css';
import './styles/MainPage4.css'
import Header from './components/header/Header';
import MainPage from './components/mainPage/MainPage4'
import Footer from './components/footer/Footer'
import ReactPlayer from 'react-player';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import VideoModal from './components/videomodal/VideoModal'

// const Modal = ({ handleClose, show, children }) => {
//   const showHideClassName = show ? "modal display-block" : "modal display-none";

//   return (
//     <div className={showHideClassName}>
//       <section className="modal-main">
//         <HighlightOffIcon className="vidCloseButton" onClick={handleClose}></HighlightOffIcon>
//         {children}
//       </section>
//     </div>
//   );
// };

function App(props) {
  const [modalState, setModalState] = useState({ show: false });
  const [vidPlaying, setVidPlaying] = useState(true);
  const [vidPlayingURL, setVidPlayingURL] = useState(null);

  function showModal(vidURL) {
    console.log("showModal in App.js called")
      setVidPlayingURL(vidURL);
      setModalState({ show: true });
      setVidPlaying(true);
  };
  
  function hideModal() {
    console.log("hideModal in App.js called")
      setModalState({ show: false });
      setVidPlaying(false);
      setVidPlayingURL(null);
  };


  return (
    <div>
      <Header />
      <MainPage showModal={showModal} hideModal={hideModal}/>
      <VideoModal show={modalState.show} handleClose={hideModal}
        vidPlaying={vidPlaying} vidPlayingURL={vidPlayingURL}
      >
      </VideoModal>
    </div>
  );
}

export default App;
