import React from 'react';
import ReactDOM from 'react-dom';
// import { Router, Route, Link, browserHistory, IndexRoute } from 'react-router'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './index.css';
import App from './App';
import EpiPlaybooks from './components/epiplaybooks/EpiPlaybooks';
import Error from './Error';
import registerServiceWorker from './registerServiceWorker';
import WebFont from 'webfontloader';

WebFont.load({
    google: {
      families: ['Roboto:300,400,700', 'sans-serif']
    }
  });

ReactDOM.render(
  // <Router history = {browserHistory}>
  <BrowserRouter>
      {/* <Route path = "/" component = {App}>
         <IndexRoute component = {App} />
         <Route path = "pbs" component = {App} />
         <Route path = "epipbs" component = {EpiPlaybooks} />
      </Route> */}
      <Switch>
        <Route path="/" component={App} exact />
        <Route path="/epipbs" component={EpiPlaybooks} />
        <Route component={Error} />
      </Switch>
  {/* </Router>, */}
  </BrowserRouter>,
  document.getElementById('root'));
registerServiceWorker();
