import React, { useEffect, useState, useRef } from 'react'
import '../../styles/MainPage4'
import pbEditor from '../../../public/assets/sample_playbook_1.png';
import Circle from './Circle';
import ReactPlayer from 'react-player';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AOS from 'aos';
import 'aos/dist/aos.css';
import EpiLogo from '../epiLogo/EpiLogo'
import { Hidden } from '@material-ui/core';
import ReactGA from 'react-ga';

const ALPHA_HOST = 'epiphani.ai'
const MASTER_HOST = 'staging.epiphani.ai.s3-website-us-west-2.amazonaws.com';
const LOCAL_HOST = 'localhost:1234'

const LEARNMOREURL = "https://epiphanihelp.freshdesk.com/a/solutions/articles/63000237374"
const EXPLORESAMPLEPLAYBOOKSURL = "https://epiphanihelp.freshdesk.com/support/solutions/articles/63000206739-ready-to-use-sample-playbooks"
// const CREATEPLAYBOOKVID = "https://youtu.be/DAOqdLNXDIs"
const CREATEPLAYBOOKVID = "https://s3-us-west-2.amazonaws.com/epiphani.ai/Videos/SingleNodePlaybook.mp4"

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";
  
    return (
      <div className={showHideClassName}>
        <section className="modal-main">
          <HighlightOffIcon className="vidCloseButton" onClick={handleClose}></HighlightOffIcon>
          {children}
          {/* <button onClick={handleClose}>close</button> */}
        </section>
      </div>
    );
};

function customPagination(pages, activePage, onClick) {
    return (
    <div className="flexContainer flexRow" style={{marginTop: "10px"}}>
        {pages.map(page => {
        const isActivePage = activePage === page
        return (
            <Circle
            key={page}
            onClick={() => onClick(page)}
            active={isActivePage}
            />
        )
        })}
    </div>
    );
}

function MainCarouselItem2(props) {
    //Google Analytics start
  var trackingID = ""
  if (window.location.host.toLowerCase() === ALPHA_HOST) {
    //console.log("Alpha GoogleAnalytics Tracking")
    
      //console.log("data ",data)
      //console.log(data.default.GOOGLE_ANALYTICS_TRACKING_ID.TRACKING_ID)
      trackingID = "UA-175911025-1"
      //console.log("trackingID ",trackingID)
    
  } else if ((window.location.host.toLowerCase() === MASTER_HOST) ||
    (window.location.host.toLowerCase() === LOCAL_HOST)) {
   // console.log("Master/Local GoogleAnalytics Tracking")
    trackingID = "UA-175911025-1"
  }

  ReactGA.initialize(trackingID); 
  
  //Event - Add custom tracking event.
  const Event1 = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  };
  //end of GoogleAnalytics

    const carousel = useRef();
    // const carouselTimings = [ 13.75, 8.5, 13.75 ];
    const carouselTimings = [ 10.5, 20, 20 ];
    const [nowShowingIndex, setNowShowingIndex] = useState(0);
    const [modalState, setModalState] = useState({ show: false });
    const [vidPlaying, setVidPlaying] = useState(true);
    const [vidPlayingURL, setVidPlayingURL] = useState(null);
    const [startAnimation, setStartAnimation] = useState({});

    function showModal(vidURL) {
        setVidPlayingURL(vidURL);
        setModalState({ show: true });
        setVidPlaying(true);
    };
    
    function hideModal() {
        setModalState({ show: false });
        setVidPlaying(false);
        setVidPlayingURL(null);
    };

    useEffect(() => {
        document.addEventListener('keyup', (e) => {
            if (e.keyCode === 27) hideModal();
        })
    }, []);
    
    // useEffect(() => {
    //     console.log("In props.animatePage useEffect = ", props.animatePage)
    //     if (props.pageNo === props.animatePage || props.animatePage === -1) {
    //         console.log("setStartAnimation = ", {"data-aos":"fade-right", "data-aos-delay":"800"});
    //         setStartAnimation({"data-aos":"fade-right", "data-aos-delay":"800"});
    //     } else {
    //         setStartAnimation({});
    //     }
    // }, [props.animatePage])

    function rotateCarousel() {
        carousel.current.goTo(nowShowingIndex);
        setTimeout(() => { 
            setNowShowingIndex((p) => { return (p+1) % carouselTimings.length});
            }, carouselTimings[nowShowingIndex] * 1000
        );
    }
    
    // useEffect(() => {
    //     rotateCarousel();
    // }, [nowShowingIndex]);

    function handleLearnMoreClick() {
        Event1("epiphani.ai", "learnMoreClick", "epiphani.ai")
        window.open("https://epiphanihelp.freshdesk.com/support/solutions/articles/63000237374-introduction-to-epiphani-playbooks", "_blank")
    }
    
    return (
        <>
        <Hidden smUp>
            <div className="flexContainer flexRow" style={{width: "90vw"}}>
                <div
                    { ...startAnimation }>
                    <div className="mainPageLargeText">
                    <span>Use epiphani to</span><span className="highlight1">share</span>
                    <span>{' '}your playbooks</span>
                    </div>
                    <div className='flex' style={{textAlign: 'center', padding: '5px 0'}}>
                    <img className="screenShot" src={pbEditor} alt="pbEditor" />
                    </div>
                    <div className="mainTextBlock">
                    <span>
                    Share and run your playbooks through slack and other collaboration tools
                    </span>
                    <br />
                    {/* <div style={{marginTop: "2rem"}}></div> */}
                    <div style={{margin: 'auto'}} 
                    className="learnMoreButton"
                        onClick={handleLearnMoreClick}>
                        Learn more</div>
                    </div>
                </div>
                    {/* <div style={{marginTop: "2rem"}}></div> */}
                    {/* <div className="flexContainer flexRow flexSpaceEvenly" style={{marginTop: "10vh"}}> */}
            </div>
        </Hidden>
        <Hidden xsDown>
            <div className="flexContainer flexRow" style={{width: "90vw"}}>
                <div className="mainPageLargeText" 
                    style={{width: "25vw"}}
                    { ...startAnimation }>
                    <div className="mainTextBlock">
                    {/* <span>
                    Automate
                    </span>
                    <br />
                    <span className="highlight2">
                    Share
                    </span>
                    <br />
                    <span>
                    Empower
                    </span> */}
                    {/* <span className="highlight2">
                    Share
                    </span>
                    <span>capabilities within your organization</span> */}
                    <span>Use epiphani to <span className="highlight1">share</span>
                    {' '}your playbooks</span>
                    </div>
                </div>
                    {/* <div style={{marginTop: "2rem"}}></div> */}
                    {/* <div className="flexContainer flexRow flexSpaceEvenly" style={{marginTop: "10vh"}}> */}
                <div className="centerize" data-aos="fade-up" data-aos-offset="300"
                    style={{width: "40vw"}}>
                    <img className="screenShot" src={pbEditor} alt="pbEditor" />
                    {/* <div className="captionText centerize">Holistic automation is currently hard</div> */}
                </div>
                <div data-aos="fade-left" data-aos-delay="800"
                    className="mainPageText" style={{width: "25vw"}}>
                    <span>
                    Share and run your playbooks through slack and other collaboration tools
                    </span>
                    <br />
                    {/* <div style={{marginTop: "2rem"}}></div> */}
                    <div className="learnMoreButton"
                        // onClick={() => window.open(CREATEPLAYBOOKVID, "_blank")}>
                        // onClick={() => { showModal(CREATEPLAYBOOKVID) }}>
                        onClick={handleLearnMoreClick}>
                        Learn more</div>
                </div>
            </div>
            </Hidden>
        </>
    )
}

export default MainCarouselItem2;