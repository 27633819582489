import React, { useState, useEffect, useRef } from 'react';
import Formsy from 'formsy-react';
import { addValidationRule } from 'formsy-react';
import InputField from './InputField';
import '../../styles/KeepMePosted.scss';
import emailjs from 'emailjs-com';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneIcon from '@material-ui/icons/Done';
import { HashLink as Link } from 'react-router-hash-link';

function KeepMePosted(props) {
  const [canSubmit, setCanSubmit] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [submitState, setSubmitState] = useState("idle");
  const theForm = useRef(null);

  function disableButton() { setCanSubmit(false); }
  function enableButton() { setCanSubmit(true); }
  function resetForm() {
      setUserName(p => "");
      setUserEmail(p => "");
      setCanSubmit(p => false);
      theForm.current.reset();
  }
  function submit(model) {
    props.Event1("epiphani.ai", "stayInformedClick: " , "epiphani.ai")
    setSubmitState("submitting");
    // let emailSim = new Promise((resolve, reject) => {
    //     setTimeout(function() {
    //         reject()
    //     }, 4000)
    // })
    // emailSim
    emailjs.send("epiphani_gmail", "contact_form", model, "user_ssntaSffy5728lpLISq2v")
        .then(function(response) {
            // console.log('SUCCESS!', response.status, response.text);
            setSubmitState("success");
            setTimeout(() => { resetForm(); setSubmitState("idle"); }, 3000)
        }, function(error) {
            // console.log('FAILED...', error);
            setSubmitState("fail");
            setTimeout(() => { resetForm(); setSubmitState("idle"); }, 3000)
        });
  }

  useEffect(() => {
    resetForm();
  }, []);

  /* Prevents just a blank string from being entered */
  function isNotBlankString (values, value) {
    return value && value.trim().length > 0;
  }
  addValidationRule('isNotBlankString', isNotBlankString);

  return (
    <div className="keepMePostedCard" id="signup">
    <div className="keepMePostedTitle">Sign up for alpha</div>
    <Formsy ref={theForm} onValidSubmit={submit} onValid={enableButton} onInvalid={disableButton}>
        <InputField title="Name" name="user_name" validations={{maxLength: 50}}
          setValue={setUserName} value={userName}
          validationError="Just the first 50 chars are sufficient."/>
        <InputField title="Email" name="user_email" validations={{isEmail: true, maxLength: 50, isNotBlankString: true}}
          setValue={setUserEmail} value={userEmail}
          validationError="Please enter a valid email address." required
          />
        {/* We can enable the message box if we want to convert the Stay Informed box into a contact us dialog */}
        {/* <InputField title="Message" name="message" value="Ignore this." /> */}
      <div className="customSubmitRow">
        <button type="submit" disabled={!canSubmit}>
          {submitState === "submitting" ? <CircularProgress size='1em' style={{marginRight: '5px',color:'#ffffff'}} /> : null}
          {submitState === "success" ? "Thank you!" : ""}
          {submitState === "idle" || submitState === "submitting" ? "Yes, sign me up!" : ""}
          {submitState === "fail" ? "Hmm, could you retry in a bit please ?" : "" }
        </button>
      </div>
    </Formsy>
    </div>
  );
}

export default KeepMePosted;
